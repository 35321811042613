import { createSlice } from "@reduxjs/toolkit";

export const receiptsSlice = createSlice({
  name: "receipts",
  initialState: {
    totalReceipts: 0,
    travelReceipts: 0,
    educationReceipts: 0,
    otherReceipts:0,
    carReceipts:0,
    homeOfficeReceipts:0,
    officeSuppliesReceipts:0,
    maxReceiptByUsersCount: ""
  },
  reducers: {
    countReceiptsSuccess: (state, action) => {
      state.totalReceipts = action.payload.totalReceipts;
      state.travelReceipts = action.payload.travelReceipts;
      state.educationReceipts = action.payload.educationReceipts;
      state.otherReceipts = action.payload.otherReceipts;
      state.carReceipts = action.payload.carReceipts;
      state.homeOfficeReceipts = action.payload.homeOfficeReceipts;
      state.officeSuppliesReceipts = action.payload.officeSuppliesReceipts;
      state.maxReceiptByUsersCount = action.payload.maxReceiptByUsersCount
    },
   
  }
});

// Action creators are generated for each case reducer function
export const {
  countReceiptsSuccess
} = receiptsSlice.actions;

export default receiptsSlice.reducer;
